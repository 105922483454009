import axios from "axios";
import { useState } from "react";
import Aside from "./Components/Aside";
import Header from "./Components/Header";
import Layout from "./Components/Layout";

function App() {
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [type, setType] = useState("customer");

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(name, balance, type);
    axios
      .post("https://wp.ahman.in/api/party", {
        name,
        balance,
        type,
      })
      .then((response) => {
        alert(response.data);
      });
  };

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Aside />
      <div class="flex-grow text-gray-800">
        <Header />
        <div className="bg-gray-200 p-6 m-6 rounded-lg">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Party Name
          </label>
          <input
            name="name"
            type="text"
            className="bg-white rounded-lg shadow-lg p-4 mb-4"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Balance
          </label>
          <input
            name="balance"
            type="text"
            className="bg-white rounded-lg shadow-lg p-4 mb-4"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
          />
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Type
          </label>
          <select
            name="type"
            className="bg-white rounded-lg shadow-lg p-4 mb-4"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="customer">Customer</option>
            <option value="supplier">Supplier</option>
          </select>
          <button
            onClick={submitHandler}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
          >
            Save
          </button>
        </div>
        <Layout />
      </div>
    </div>
  );
}

export default App;
